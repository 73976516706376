import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, inject, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { LayoutModule } from 'app/layout/layout.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';

import { AccountModule } from './main/account/account.module';
import { RegisterComponent } from './main/account/register/register.component';
import { AppConfigModule } from './main/app-config/app-config.module';
import { ChangelogComponent } from './main/changelog/changelog.component';
import { ChangelogModule } from './main/changelog/changelog.module';
import { ModalConfirmConfigurableComponent } from './main/common/components/modal-confirm-configurable/modal-confirm-configurable.component';
import { ModalErrorComponent } from './main/common/components/modal-error/modal-error/modal-error.component';
import { AdminGuard } from './main/common/guards/admin.guard';
import { AuthGuard } from './main/common/guards/login.guard';
import { MY_MAT_TOOLTIP_DEFAULT_OPTIONS, SharedModule } from './main/common/shared.module';
import { QrComponent } from './main/qr/qr.component';
import { UsersModule } from './main/users/users.module';
import { InterceptorErrorsService } from './services/interceptor-errors.service';
import { StoreCommonService } from './main/common/services/store-common.service';

const appRoutes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./main/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'os-image',
    loadChildren: () => import('./main/os-image/os-image.module').then((m) => m.OsImageModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'devices',
    loadChildren: () => import('./main/devices/devices.module').then((m) => m.DevicesModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'batches',
    loadChildren: () => import('./main/batches/batches.module').then((m) => m.BatchesModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'running-applications',
    loadChildren: () =>
      import('./main/running-applications/running-applications.module').then(
        (m) => m.RunningApplicationsModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'application',
    loadChildren: () => import('./main/apps/library.module').then((m) => m.LibraryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('./main/account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./main/users/users.module').then((m) => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auditlogs',
    loadChildren: () => import('./main/auditlogs/auditlogs.module').then((m) => m.AuditlogsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'qr',
    component: QrComponent
  },
  {
    path: 'changelog',
    component: ChangelogComponent
  },

  {
    path: '**',
    redirectTo: 'devices/devices-list'
  }
];

function initializeKeycloak(keycloak: KeycloakService) {
  const storeCommonService = inject(StoreCommonService);

  return () => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init({
          config: {
            url: environment.auth_server,
            realm: environment.keycloak_realm,
            clientId: environment.auth_client
          },
          initOptions: {
            onLoad: 'check-sso',
            checkLoginIframe: false,
            pkceMethod: 'S256',

            checkLoginIframeInterval: 1
          },

          enableBearerInterceptor: true
        });

        const keycloakAuth = keycloak.getKeycloakInstance();
        storeCommonService.setToken(keycloakAuth.token);

        const updateToken = async (): Promise<string> => {
          try {
            const refreshed = await keycloakAuth.updateToken(10);
            if (refreshed) {
              storeCommonService.setToken(keycloakAuth.token);
            }
            return keycloakAuth.token;
            // await keycloakAuth.updateToken(5);
            // storeCommonService.setToken(keycloakAuth.token);
            // return keycloakAuth.token;
          } catch (error) {
            throw error;
          }
        };
        const login = async (): Promise<void> => {
          try {
            await keycloakAuth.login();
          } catch (error) {
            throw error;
          }
        };
        setInterval(() => {
          updateToken(); // Intentar renovar el token periódicamente
        }, 1000);
        keycloakAuth.onTokenExpired = () => {
          updateToken();
        };

        resolve('Keycloak initialized');
      } catch (error) {
        reject(error);
      }
    });
  };
}

@NgModule({
  declarations: [AppComponent, ModalErrorComponent, ModalConfirmConfigurableComponent, QrComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    TranslateModule.forRoot(),
    // Material moment date module
    MatMomentDateModule,
    MatFormFieldModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatToolbarModule,
    KeycloakAngularModule,
    MatSnackBarModule,

    // App modules
    LayoutModule,
    ChangelogModule,
    AccountModule,
    UsersModule,
    NgIdleKeepaliveModule.forRoot(),
    GoogleTagManagerModule.forRoot({
      id: environment.tag_manager
    }),
    AppConfigModule,
    SharedModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorErrorsService,
      multi: true
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: MY_MAT_TOOLTIP_DEFAULT_OPTIONS }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
