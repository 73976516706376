import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  constructor(private storageService: StorageService) {}

  public storeSort(event: Sort, data, view?: string): any {
    data.sort((a, b) => {
      //console.log(event.active, event.direction);
      let compareAux: number;
      const isAsc = event.direction === 'asc';
      switch (event.active) {
        case 'deviceName':
        case 'developer':
        case 'fullName':
        case 'name':
        case 'description':
        case 'nmeBatch':
        case 'author':
          return this.compare(a[event.active].toLowerCase(), b[event.active].toLowerCase(), isAsc);
        case 'alive':
        case 'deviceAlive':
          // Compare by 'alive' status
          if (a.alive !== undefined) {
            if (a.alive !== b.alive) {
              return isAsc ? (a.alive ? -1 : 1) : a.alive ? 1 : -1;
            }
            // If 'alive' status is the same, compare by 'name' alphabetically
            if (a.alive === b.alive) {
              if (a.name !== undefined && a.name !== b.name) {
                return a.name.localeCompare(b.name);
              }
            }
          }
          if (a.deviceAlive !== undefined) {
            if (a.deviceAlive !== b.deviceAlive) {
              return isAsc ? (a.deviceAlive ? -1 : 1) : a.deviceAlive ? 1 : -1;
            }
            if (a.deviceAlive === b.deviceAlive) {
              if (a.deviceName !== undefined && a.deviceName !== b.deviceName) {
                return a.deviceName.localeCompare(b.deviceName);
              }
            }
          }

        case 'status':
          if (a.actived) {
            compareAux = this.compare(a.activated, b.activated, isAsc);
            return compareAux === 0 ? this.compare(a.name, b.name, isAsc) : compareAux;
          } else {
            compareAux = this.compare(a.status, b.status, isAsc);
            return compareAux === 0 ? this.compare(a.name, b.name, isAsc) : compareAux;
          }
        case 'running':
          compareAux = this.compare(a.runningApps, b.runningApps, isAsc);
          return compareAux === 0
            ? this.compare(a.installedApps, b.installedApps, isAsc)
            : compareAux;
        case 'lastSeen':
          return (compareAux = this.compare(a.lastSeen, b.lastSeen, isAsc, true));
        case 'createdBatch':
          return (compareAux = this.compare(a.createdBatch, b.createdBatch, isAsc, true));
        case 'timestamp':
          return (compareAux = this.compare(a.timestamp, b.timestamp, isAsc, true));
        default:
          return this.compare(a[event.active], b[event.active], isAsc);
        case 'size':
          return this.compare(parseInt(a.size.split(' ')), parseInt(b.size.split(' ')), isAsc);
      }
    });
    if (view === undefined) {
      view = '';
    }
    this.storageService.storeListSorting(JSON.stringify(event), view);
    return data;
  }

  compare(a: number | string | Date, b: number | string | Date, isAsc: boolean, isDate?: boolean) {
    if (typeof a === 'boolean' && typeof b === 'boolean') {
      return (a === b ? 0 : a ? -1 : 1) * (isAsc ? -1 : 1);
    }
    if (isDate) {
      if (a === '') {
        a = new Date();
      }
      if (b === '') {
        b = new Date();
      }

      return (
        (new Date(a).getTime() === new Date(b).getTime()
          ? 0
          : new Date(a).getTime() < new Date(b).getTime()
            ? -1
            : 1) * (isAsc ? -1 : 1)
      );
    }
    if (a === b) {
      return 0;
    }

    return (a < b ? -1 : 1) * (isAsc ? -1 : 1);
  }
}
